import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const SundayDealz = () => {
  const headingbanner = {
    title: `Sunday Dealz`,
    content: `A Flash Sales E-Commerce Platform`,
  };

  const data = {
    images: ["sunday.webp", "sunday-2.webp"],
    firstAlt: "Product List Page of Flash Sale E-commerce platform",
    firstTitle: "Product List Page",
    secondAlt: "Order and payment details of the customer",
    secondTitle: "Payment Details",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["Ecommerce"],
      },
      {
        title: "What we did",
        text: ["Branding", "UI UX Designing", "UI Development"],
      },
      {
        title: "Platform",
        text: ["Responsive Website"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `Our main challenge was to successfully engage and retain users on their once-a-week sale, without the use of a landing page. `,
          `We needed to create an easy-to-use and straightforward UI that would cater to a diverse range of users, including students, housewives, and working professionals. `,
          `Additionally, we also had to keep users engaged on our website even on non-sale days. Another challenge was simplifying the sellers' registration and onboarding process. Overall, we had to ensure a smooth user experience on their platform.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `Our solution aimed to create a highly engaging and mobile-responsive experience for both buyers and sellers. `,
          `We prioritized providing an easy and smooth experience for all users, regardless of their technological adaptability. `,
          `We also focused on enhancing the capacity of sellers to apply for sales on the platform. Ultimately, our goal was to create a simple and adaptable yet unique experience for mobile users.`,
        ],
      },
    ],
    image: ["sunday-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: "Sign up",
    para: [
      `We created a signup form to facilitate an easy checkout for sellers and buyers during the flash- sales. `,
    ],
    content: {
      image: ["sunday-projectone.webp"],
      imageAlt: 'Sign Up',
      imageTitle: 'Sign Up',
      text: `It also helps to offer a personalised shopping experience and improved security to the users. `,
    },
    content2: {
      imageAlt: "UI screen of men's product page",
      imageTitle: 'Product Page',
      image: ["sunday-projectone-2.webp"],
      text: `This product page offers detailed information to the users regarding a particular product like time left, products left, users viewing the product etc. With this platform, we aim to enhance user engagement in the platform. `,
    },
  };

  const projectData2 = {
    title: `Express Checkout`,
    para: [
      `We also created a mobile version of the platform to engage and retain customers while also increasing checkouts. `,
    ],
    content: {
      image: ["sunday-projecttwo.webp"],
      imageAlt: "Mobile Responsive to review order and checkout",
      imageTitle: 'Express Checkout',
    },
  };

  const projectData3 = {
    title: "Success Page",
    para: [
      `This page shows the user’s details such as address, card details, contact etc. for a smooth checkout experience and also shows the list of orders placed for a better user experience.`,
    ],
    content: {
      image: [
        "sunday-projectthree.webp",
        "sunday-projectthree-2.webp",
        "sunday-projectthree-3.webp",
        "sunday-projectthree-4.webp",
        "sunday-projectthree-5.webp",
      ],
      firstAlt: 'UI screen of successful placed orders ',
      firstTitle: 'Your Orders',
      secondAlt: 'Add details to place order',
      secondTitle: 'Express Checkout Details',
      thirdAlt: 'UI screen to show payment status',
      thirdTitle: 'Payment Successful',
      fourthAlt: 'Join to become seller',
      fourthTitle: 'Join Us',
      fifthAlt: 'Mobile Responsive to sign up and add address',
      fifthTitle: 'Sign up with Mobile',
    },
  };

  const conclusionData = [
    {
      para: [
        `Our solutions successfully addressed the challenges of engaging and retaining users on a once-a-week flash sale e-commerce platform.`,
        `By creating an easy-to-use UI and prioritizing mobile responsiveness, we ensured a seamless shopping experience for users from diverse backgrounds.`,
        `Additionally, we simplified the sellers' registration process, enhancing their ability to participate in sales. `,
        `Overall, our solution aimed to provide a unique and adaptable experience for mobile users, resulting in a highly engaging platform for both buyers and sellers.`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={37} />
      </div>
    </Layout>
  );
};

export default SundayDealz;

export const Head = () => (
  <Seo title="Sunday Dealz - Octet Design Studio" description="An e-commerce Flash sale website for USA where we worked from ideation to UI design. We did Branding, UI UX Designing and Nuxtjs Development for them." />
)